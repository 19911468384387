import { render, staticRenderFns } from "./news-block.vue?vue&type=template&id=c640a292"
import script from "./news-block.vue?vue&type=script&lang=js"
export * from "./news-block.vue?vue&type=script&lang=js"
import style0 from "./news-block.vue?vue&type=style&index=0&id=c640a292&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsCard: require('/opt/new.yaravia.ru/src/components/news-card.vue').default})
