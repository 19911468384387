
import {rzButton} from 'razlet-ui';

export default {
  name: 'news-block',
  components: {
    rzButton,
  },
  props: {
    content: {
      type:Object,
      default: () => ({
        pages: [],
      }),
    },
  },
  data() {
    return {
      selectedId: 1,
      deviceSizes: {
        sm: 992,
        md: 1100,
        lg: 1200,
        xl: 1300,
        xxl: 1450,
      },
    };
  },
  computed: {
    slicedPages() {
      return this.isMobile ? this.content.pages.slice(0, 3) : this.content.pages;
    },
  },
  methods: {
    nextPage(ind) {
      const width = window.innerWidth;
      // eslint-disable-next-line no-nested-ternary
      const breakPoint = width <= this.deviceSizes.sm ? 992 : width <= this.deviceSizes.md ? 315 : width <= this.deviceSizes.lg ? 345 : width <= this.deviceSizes.xl ? 380 : width <= this.deviceSizes.xxl ? 416 : 440;
      const step =  breakPoint + 25;
      this.$refs.slider.scrollLeft = this.selectedId > ind 
        ? this.$refs.slider.scrollLeft - step * (this.selectedId - ind) 
        : this.$refs.slider.scrollLeft + step * (ind - this.selectedId);
      this.selectedId = ind;
    },
  },
};
