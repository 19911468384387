
import { mapActions } from 'vuex';
import MainBlock from '~/views/main-block';
import TabloBlock from '~/views/tablo-block';
import ScheduleBlock from '~/views/schedule-block';
import NewsBlock from '~/views/news-block';

export default {
  name: 'index',
  layout: 'index',
  components: {
    MainBlock,
    TabloBlock,
    ScheduleBlock,
    NewsBlock,
  },
  async asyncData ({ store, error }) {
    const snippets = await store.dispatch('getData', {path:''})
      .then(res => {
        return res.content.snippets;
      })
      .catch(e => {
        error(e);
      });
    const tablo = await store.dispatch('getTablo', 'today')
      .catch(e => {
        error(e);
      });
    const schedule = await store.dispatch('getFullSchedule')
      .catch(e => {
        error(e);
      });
    const news = await store.dispatch('getNewsList')
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
        return {};
      });
    return {
      schedule, 
      news, 
      snippets, 
      tablo,
      slides: snippets.find(snippet => snippet.template === 'slider').content.elements,
      imagesUrls: snippets.find(snippet => snippet.template === 'slider').content.elements.map(el => el.images.url),
    };
  },
  data () {
    return {
      schedule: {},
      news: {},
      snippets: [],
      slides: [],
      tablo: {},
      tabloLoading: false,
      imagesUrls: [],
    };
  },
  methods: {
    ...mapActions(['getTablo']),
    refreshTablo(day) {
      this.tabloLoading = true;
      delete this.tablo.in;
      delete this.tablo.out;

      this.getTablo(day).then(res => {
        this.tablo = res;
        this.tabloLoading = false;
      });
    },
  },
  head() {
    const links = this.imagesUrls.map(url => {
      return { rel: 'preload', as: 'image', href: `${process.env.suluURL}${url}` };
    });
    return {
      link: links,
    };
  },
};
